import './file_field.styles.scss';

const FileField = (props: any) => {
  const {label, fieldName, placeholder, value, onChangeHandler} = props;
  return (
    <div className="file-field-container">
      <label className="field-label">{label}</label>
      <input className="field-input" value={value} type="file" name={fieldName} placeholder={placeholder}  onChange={onChangeHandler}/>
    </div>
  )

}

export default FileField;