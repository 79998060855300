import './bank_select_field.styles.scss';
const BANK_LIST = [
  [],
  ["AmBank Malaysia", "ambank"],
  ["CIMB", "cimb"],
  ["Hong Leong Bank", "hong leong"], 
  ["Maybank Malaysia", "maybank"],
  ["Public Bank Berhad", "public_bank"], 
  ["RHB Bank", "rhb"], 
  ["UOB Malaysia", "uob"], 
  ["OCBC Bank", "ocbc"], 
]
const BankSelectField = (props: any) => {
  const {selectChangeHandler} = props;
  return (
    <div className="bank-select-field-container">
      <label className="field-label">Select Bank</label>
      <select className="field-input" name="bank_name" onChange={selectChangeHandler}>
        {BANK_LIST.map(bank => {
          return(
          <option value={bank[1]}>{bank[0]}</option>
          )
        })}
      </select>
    </div>
  )
}

export default BankSelectField;