import { useEffect, useState, cloneElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setAuthOnSignInSuccess, setCurrentUser } from '../../redux/user/user.actions';
import cookie from 'js-cookie';

import User from '../../model/user.model';

import PasswordField from '../../components/forms/password_field.components';
import SigninSignupPageTemplate from './signin_signup_page_template.hoc.component';
import TextField from '../../components/forms/text_field.components';
import SubmitBtn from '../../components/forms/submit_btn.components';

import { constructUseFromCookies } from '../../utils/user_cookies.utils';
import { cognitoSignin } from '../../adapters/aws_cognito.adapters';

import './signin_page.styles.scss';

const SigninPage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({username: "", password: ""});
    const [currentUser, setCurrentUser] = useState(() => constructUseFromCookies());
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const onFormFieldChange = (event: any) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })    
    }    

    //--  constructor not required as state is not required because 
    //--  we are now using redux store
    // constructor(props: any){
    //     super(props);

    //     this.state = {
    //         username: "",
    //         email: "",
    //         password: ""
    //     }
    // }

    // componentDidMount(){
    //     const user: User = {
    //         username:"testing",
    //         email: "testing@testing.com",
    //         password:"psas",
    //         confirmPassword:"confirm"
    //     }
    //     setCurrentUser(user);
    // }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);

        let {username, password} = formData;
        await cognitoSignin(username, password)
        .then((response: any)=>{
            // response.status = 200
            // response.data.message == "success"
            let isSuccess = response.data.success;
            if (isSuccess) {
                let accessToken = response.data.data.access_token;
                let expiresInSeconds = response.data.data.expires_in;
                let idToken =  response.data.data.id_token;
                let refreshToken = response.data.data.refresh_token;
                //  response.data.data.token_type
                
                let expiresInDate = new Date(new Date().getTime() + expiresInSeconds * 1000);

                // dispatch(setAuthOnSignInSuccess(user))
                cookie.set("idToken", idToken, {expires: expiresInDate});
                cookie.set("accessToken", accessToken, {expires: expiresInDate});
                cookie.set("refreshToken", refreshToken, {expires: expiresInDate});
                cookie.set("username", username, {expires: expiresInDate});

                setCurrentUser(constructUseFromCookies());
                navigate('/dashboard')
            } else {
                alert("There were issues signing in");
            }
        })
        .catch((err: any) => {
            setErrorMessage(err.response.data.message);
            window.scrollTo(0, 0)
            setTimeout(()=> {
                setErrorMessage("");
            }, 5000);
        });

        setIsLoading(false);
    }

    return(
        <SigninSignupPageTemplate errorMessage={errorMessage}>
            <div className="form-container">
                <h3 className="form-title">Sign In</h3>
                <form onSubmit={handleSubmit} className="signin-form">
                    <TextField fieldType="text" label="username" fieldName="username" onChangeHandler={onFormFieldChange}/>
                    <PasswordField fieldType="password" label="password" fieldName="password" onChangeHandler={onFormFieldChange}/>
                    <SubmitBtn buttonType="submit" buttonName={isLoading ? "Signing In...": "Sign In"} />
                </form>
                <div className="links-container">
                    <Link to="/signup">Don't have an account yet? Sign up now</Link>
                    <Link to="/confirm_signup">Confirm your account here</Link>
                    <Link to="/forgot_password">Forogt your password? Reset it here</Link>
                </div>

            </div>
        </SigninSignupPageTemplate>
    );
}


// connect redux store to this component and load data from the store and pass the data into this component
// in presence of hooks, userSelector from react-redux library is replacing this
// const mapStateToProps = (state: any) => ({
//   currentUser: state.user.currentUser,
// })

// in presence of hooks, useDispatch from react-redux library is replacing this
// const mapDispatchToProps = (dispatch: any) => ({
//     setCurrentUser: (user: User) => dispatch(setCurrentUser(user)) 
// })

// necessary code base to connect this component to the redux store
// in presence of hooks, the connect() function is also no longer use, but its non breaking
// export default connect(mapStateToProps, mapDispatchToProps)(SigninPage);
export default SigninPage;