import './dashboard_template.styles.scss';
import { useState, cloneElement } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';

import loading from '../../maven-eight-loading.gif';

const DashboardTemplate = ({children, isLoading, errorMessage}: {children: any, isLoading: boolean, errorMessage: string}) => {
  const location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate();

  const signout = () => {
    cookie.remove("idToken");
    cookie.remove("username");
    cookie.remove("refreshToken");
    cookie.remove("accessToken");
    navigate("/")
  }

  return (
    <div>
      <div className={"error-message-bar " + (errorMessage.length == 0 ? "error-message-bar-hide" : "")}>{errorMessage}</div>
      <div className="dashboard-page">

        <div className="page-top-container">
          <div className="header-navigation-container">
            <div className="header-container">
              <h1 className="main-header">Maven Eight</h1>
            </div>
            <div className="top-navigation-panel-container">
                <div className="top-navigation-panel">
                  <Link to="/dashboard" className={"nav-button" + (pathName == "/dashboard" ? " nav-selected" : "")}>Dashboard</Link>
                  <Link to="/dashboard/convert-csv" className={"nav-button" + (pathName == "/dashboard/convert-csv" ? " nav-selected" : "")}>Convert Csv</Link>
                  <Link to="/dashboard/convert-pdf" className={"nav-button" + (pathName == "/dashboard/convert-pdf" ? " nav-selected" : "")}>Convert Pdf</Link>
                </div>
                <div className="top-navigation-panel">
                  <p className="nav-button signout" onClick={signout} >Sign Out</p>
                </div>
            </div>
          </div>
        </div>
        <div className="page-bottom-container">
          <div className="page-content-container">
            { isLoading 
            ? 
              <div className="loading-gif-container">
                <div className="loading-message-box">
                  <img src={loading} className="loading-gif" />
                  <p>Working on it... please wait for a moment</p>
                </div>
              </div>
            :
              children
            }
          </div>
        </div>
        <div className="footer-container">
            <p>&copy; 2022 All Rights Reserverd</p>
        </div>
      </div>
    </div>
  );
}

export default DashboardTemplate;