import {Routes, Route} from 'react-router-dom';

import HomePage from './pages/home/home_page.component';
import SigninPage from './pages/signin_signup_pages/signin_page.component';
import SignupConfirmationPage from './pages/signin_signup_pages/signup_confirmation_page.component';
import SignupPage from './pages/signin_signup_pages/signup_page.component';
import BankStatementConverterDashboard from './pages/bank_statement_converter_pages/bank_statement_converter_dashboard.component';
import PageNotFound from './pages/error_pages/page_not_found.component';
import PdfBankStatementFormPage from './pages/bank_statement_converter_pages/pdf_bank_statement_form_page.components';
import CsvConverterPage from './pages/bank_statement_converter_pages/csv_converter_page.component';
import Navbar from './components/navbar/navbar.components';

import PrivateRoute from './components/custom_routings/private_route.components';
import PublicRoute from './components/custom_routings/public_route.components';
import ForgotPasswordPage from './pages/signin_signup_pages/forgot_password.component';


function App() {
  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<HomePage/>}/> */}
        <Route path="/" element={
          <PublicRoute>
            <SigninPage/>
          </PublicRoute>
        }/>
        <Route path="/signup" element={
          <PublicRoute>
            <SignupPage/>
          </PublicRoute>
        }/>
        <Route path="/confirm_signup" element={
          <PublicRoute>
            <SignupConfirmationPage/>
          </PublicRoute>
        }/>
        <Route path="/forgot_password" element={
          <PublicRoute>
            <ForgotPasswordPage/>
          </PublicRoute>
        }/>
        <Route path="/dashboard" element={
          <PrivateRoute>
            <BankStatementConverterDashboard />
          </PrivateRoute>
        }/>
        <Route path="/dashboard/convert-csv" element={
          <PrivateRoute>
            <CsvConverterPage/>
          </PrivateRoute>
        }/>
        <Route path="/dashboard/convert-pdf" element={
          <PrivateRoute>
            <PdfBankStatementFormPage/>
          </PrivateRoute>
        }/>
        <Route path="*" element={<PageNotFound/>} />
      </Routes>
    </div>
  );
}


export default App;
