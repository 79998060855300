import './signin_signup_page_template.styles.scss';

const SigninSignupPageTemplate = ({children, errorMessage}:{children: any, errorMessage: string}) => {
  return (
    <div>
      <div className={"error-message-bar " + (errorMessage.length == 0 ? "error-message-bar-hide" : "")}>{errorMessage}</div>
      <div className="signin-page">
        <div className="header-container">
            <h1 className="main-header">Maven Eight</h1>
            <p className="sub-header">Automate the conversion of your bank statements <br/> to xero format in split seconds</p>
        </div>
        {children}
        <div className="footer-container">
            <p>&copy; 2022 All Rights Reserverd</p>
        </div>
      </div>
    </div>
  );
}

export default SigninSignupPageTemplate;
        