import './page_not_found.styles.scss';

const PageNotFound = () => {
  return (
    <div>
      <h1>404 Page Not Found</h1>
    </div>
  )
} 

export default PageNotFound;