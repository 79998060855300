import axios from "axios";
import { Buffer } from "buffer";
import { constructUseFromCookies } from "../utils/user_cookies.utils";

const API_URL = "https://c2vafv5uzj.execute-api.ap-southeast-1.amazonaws.com"
const FRONT_END_URL = "https://www.maveneight.cloud"


export const retrieveAllUserDocuments = () => {
  const user = constructUseFromCookies();
  const idToken: string = user.idToken!
  return axios.get(
    API_URL + "/dev/xero/parsed_files", 
    { headers: {
      'Authorization': idToken,
      'Access-Control-Allow-Origin': FRONT_END_URL,
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
    }});
}

export const retrievePdfJobsInProgress = () => {
  const user = constructUseFromCookies();
  const idToken: string = user.idToken!
  return axios.get(
    API_URL + "/dev/xero/pdf_conversion_jobs", 
    { headers: {
      'Authorization': idToken,
      'Access-Control-Allow-Origin': FRONT_END_URL,
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
    }});
}

export const convertCsvBankStatement = async (file: any, file_date: any, bank_name: any, client_name: any) => {
  const user = constructUseFromCookies();
  const idToken: string = user.idToken!
  const formData = new FormData();
  formData.append("file", file);
  formData.append("file_date", file_date);
  formData.append("bank_name", bank_name);
  formData.append("client_name", client_name);

  return await axios.post(
    API_URL + "/dev/xero/upload_csv_and_parse",
    formData,
    {headers: { 
      "Content-Type": "multipart/form-data",
      "Authorization": idToken,
      'Access-Control-Allow-Origin': FRONT_END_URL,
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token"
    }}
  );
}

export const getPresignedUrl = async (bank_name: any, statement_date: any) => {
  const user = constructUseFromCookies();
  const idToken: string = user.idToken!
  const formData = new FormData();
  formData.append("bank_name", bank_name);
  formData.append("statement_date", statement_date);

  return await axios.post(
    API_URL + "/dev/xero/generate_presigned_url", 
    formData,
    { headers: {
      'Authorization': idToken,
      'Access-Control-Allow-Origin': FRONT_END_URL,
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token"
    }});
}

export const putPdfStatementWithPresignedUrl = async (presignedUrl: string, file: string) => {
  // const formData = new FormData();
  // formData.append("file_name", file);

  return await axios.put(
    presignedUrl,
    // Buffer.from(file),
    file,
    {headers: { 
      "Content-Type": "application/pdf",
      'Access-Control-Allow-Origin': FRONT_END_URL,
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token"
    }}
  )
}


export const triggerPdfBankStatementAnalysis = async (file_name: any, statement_date: any, bank_name: any, client_name: any) => {
  const user = constructUseFromCookies();
  const idToken: string = user.idToken!
  const formData = new FormData();
  formData.append("file_name", file_name);
  formData.append("statement_date", statement_date);
  formData.append("bank_name", bank_name);
  formData.append("client_name", client_name);


  return await axios.post(
    API_URL + "/dev/xero/trigger_pdf_statement_analysis",
    formData,
    {headers: { 
      "Content-Type": "multipart/form-data",
      "Authorization": idToken,
      'Access-Control-Allow-Origin': FRONT_END_URL,
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token"
    }}
  );
}

