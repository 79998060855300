import './password_field.styles.scss';

const PasswordField = (props: any) => {
  const { label, fieldName, placeholder, value, errorMsg, onChangeHandler } = props;
  return (
    <div className="converter-form-group">
      <label className="field-label">{label}</label>
      <input className="field-input" value={value} type="password" name={fieldName} placeholder={placeholder} onChange={onChangeHandler} />
      {errorMsg && errorMsg.length > 0 ? <p className="password-error-message">{errorMsg}</p> : ""}
    </div>
  )
}

export default PasswordField;
