import cookie from 'js-cookie';

export const constructUseFromCookies = () => {
    return {
        username: cookie.get("username"),
        idToken: cookie.get("idToken"),
        accessToken: cookie.get("accessToken"), 
        refreshToken: cookie.get("refreshToken")
    }
}

export const userIsAuthenticated = () => {
  const user = constructUseFromCookies();
  return user.username && user.idToken && user.accessToken && user.refreshToken;
}