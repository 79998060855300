import './confirmation_code_fields.styles.scss';

const ConfirmationCodeFields = (props: any) => {
  const {codes, onChangeHandler}: {codes: object, onChangeHandler: any} = props;
  return(
    <div className="confirmation-codes-container">
      <p className="verification-label">Check your registered email for the verification code</p>
      <div className="six-digit-number-verification-fields">
        {Object.entries(codes).map(([key, value]) => <input className="number-verification-field" type="numeric" key={key} name={key} value={value} onChange={onChangeHandler}></input> )}
      </div>
    </div>
  )
}

export default ConfirmationCodeFields;