import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { convertCsvBankStatement } from '../../adapters/xero_converter_service_adapters';

import DashboardTemplate from './dashboard_template.hoc.component';
import TextField from '../../components/forms/text_field.components';
import SubmitBtn from '../../components/forms/submit_btn.components';
import BankSelectField from '../../components/forms/bank_select_field.component';
import DateField from '../../components/forms/date_field.components';
import FileField from '../../components/forms/file_field.components';

import './csv_converter_page.styles.scss';

const initiatialCsvFormDataState = {file: "", file_date: new Date(), bank_name: "", file_name:"", client_name:""}

const CsvConverterPage= () => {
  const [csvFormData, setFormData] = useState(initiatialCsvFormDataState)    
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onFormFieldChange = (event: any) => {
    setFormData({
        ...csvFormData,
        [event.target.name]: event.target.value
    })    
  }    

  const onFormFileChange = (event: any) => {
      setFormData({
          ...csvFormData,
          file: event.target.files[0],
          file_name: event.target.value,
      })    
  }
  const onSelectChange = (event: any) => {
      console.log(event.target)
      setFormData({
          ...csvFormData,
          bank_name: event.target.value,
      })    

  }
  const onDateFieldChange = (date: any) => {
      setFormData({
          ...csvFormData,
          file_date: date
      })    
  }
 

  const handleCsvConversionSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);

    let {file, file_date, bank_name, client_name} = csvFormData;

    let formatted_file_date = moment(file_date).format("DD-MM-yyyy");

    convertCsvBankStatement(file, formatted_file_date, bank_name, client_name)
    .then(response => {
      setFormData(initiatialCsvFormDataState);
      setIsLoading(false);
      navigate("/dashboard");
    })
    .catch((err: any) => {
      setErrorMessage(err.response.data.message);
      window.scrollTo(0, 0)
      setTimeout(()=> {
          setErrorMessage("");
      }, 5000);
      setFormData(initiatialCsvFormDataState);
    });
    setIsLoading(false);
  }
  return(
    <DashboardTemplate isLoading={isLoading} errorMessage={errorMessage}>
      <div className="csv-converter-page">
        <div className="csv-converter-form-container">
          <h3 className="form-title">Upload your bank statements in CSV format</h3>
          <form onSubmit={handleCsvConversionSubmit}>
            <TextField fieldType="text" label="Client Name" fieldName="client_name" onChangeHandler={onFormFieldChange}/>
            <BankSelectField selectChangeHandler={onSelectChange} />
            <DateField selected={csvFormData.file_date} label="File Date" dateChangeHandler={onDateFieldChange} />
            <FileField label="File" fieldName="file" value={csvFormData.file_name} onChangeHandler={onFormFileChange}/>
            <SubmitBtn buttonType="submit" buttonName="Convert Csv" />
          </form>
        </div>
      </div>
    </DashboardTemplate>
  )
}

export default CsvConverterPage;