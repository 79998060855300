import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { retrievePdfJobsInProgress, retrieveAllUserDocuments } from "../../adapters/xero_converter_service_adapters";

import DashboardTemplate from "./dashboard_template.hoc.component";
import SearchField from "../../components/forms/search_field.components";

import './bank_statement_converter_dashboard.styles.scss';

import csvFileIcon from '../../csv_file_icon.png';



const BankStatementConverterDashboard = () => {
  const [documentList, setDocumentList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [jobsInProgressCount, setJobsInProgressCount] = useState(0);

  const onSearchKeyChange = (event: any) => {
    let key = event.target.value
    setSearchKey(key);
  }

  useEffect(() => {
    retrieveAllUserDocuments().then(response => {
      setDocumentList(response.data.Items)
    });

    retrievePdfJobsInProgress().then(response => {
      let jobCounts = response.data.length;
      setJobsInProgressCount(jobCounts);
    })
  }, [])

  useEffect(() => {
    console.log("Jobs Count")
    console.log(jobsInProgressCount)
    if (jobsInProgressCount > 0) {
      setTimeout(() => {
        console.log("calling backed to check any balance conversion jobs")
        retrievePdfJobsInProgress().then(response => {
          let jobCounts = response.data.length;
          setJobsInProgressCount(jobCounts);

          if (jobCounts == 0) {
            retrieveAllUserDocuments().then(response => {
              setDocumentList(response.data.Items)
            });
          }
        })
      }, 10000);
    } else {
      console.log("clearing interval");
      clearInterval();
    }
  }, [jobsInProgressCount])

  const getFilteredAndSortedDocumentList = (key: string) =>{
    return documentList.filter(doc => doc.file_name && doc.file_name.toLowerCase().includes(key.toLowerCase()) || doc.client_name && doc.client_name.toLowerCase().includes(key.toLowerCase()) ).sort((a, b) => (moment.utc(a.date_created) > moment.utc(b.date_created)) ? -1 : 1);
  }

  return (
    <DashboardTemplate isLoading={isLoading} errorMessage={errorMessage}>
      <div className="dashboard-home-page">
        <h2 className="page-title">List of Converted Bank Statements</h2>
        <SearchField value={searchKey} placeholder="filter files by keywords" onChangeHandler={onSearchKeyChange} />
        <div className={"job-count-status-bar " + (jobsInProgressCount == 0 ? "hide"  : "")}>You have {jobsInProgressCount} document(s) being processed. We will retrieve the file(s) for you in just a moment...</div>
        <div className="documents-container">
          {getFilteredAndSortedDocumentList(searchKey).length == 0 
            ? <p>No file being converted yet</p>
            : getFilteredAndSortedDocumentList(searchKey).filter(doc => doc.file_name && doc.download_url).map(doc => {
                let processed_file_name =  doc.file_name.replaceAll("_"," ")
                let date = moment.utc(doc.date_created);
                let isNew = (moment.utc(Date.now()).local().diff(moment.utc(doc.date_created).local())/60000) < 15;
                return (
                  <a href={doc.download_url} target="_blank" className="document-item-link" key={doc.id}>
                    <div className="document-item-details" key={doc.id}>
                      { isNew ? <div className="new-converted-tag">New</div> : ""}
                      <div className="icon-container">
                        <img src={csvFileIcon} width="60px" />
                      </div>
                      <div className="details-container">
                        <p className="file-name">{processed_file_name}</p>
                        <p className="file-detail">client: {doc.client_name}</p>
                        <p className="file-detail">source type: {doc.source_file_type}</p>
                        <p className="file-detail">created date: {date.local().format("DD MMM YYYY hh:mm A")}</p>
                      </div>
                    </div>
                  </a>
                )
              })
          }
        </div>
      </div>
    </DashboardTemplate>
  )
}

export default BankStatementConverterDashboard;
