import './search_field.styles.scss';
import searchIcon from '../../search_icon.png';

const SearchField = (props: any) => {
  const {fieldName, placeholder, value, onChangeHandler} = props;
  return (
    <div className="search-field-form-group">
      <div className="search-bar-container">
        <div className="icon-container">
          <img src={searchIcon} height="25px"/>
        </div>
        <input className="search-field-input" value={value} type="text" name={fieldName} placeholder={placeholder}  onChange={onChangeHandler}/>
      </div>
    </div>
  )
}

export default SearchField;
