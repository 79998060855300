import { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { getPresignedUrl, putPdfStatementWithPresignedUrl, triggerPdfBankStatementAnalysis } from '../../adapters/xero_converter_service_adapters';
import moment from "moment";

import DateField from '../../components/forms/date_field.components';
import TextField from '../../components/forms/text_field.components';
import FileField from '../../components/forms/file_field.components';
import BankSelectField from '../../components/forms/bank_select_field.component';
import SubmitBtn from '../../components/forms/submit_btn.components';
import DashboardTemplate from './dashboard_template.hoc.component';

import './pdf_bank_statement_form_page.styles.scss';


const initiatialPdfFormDataState = {file: "", statement_date: new Date(), bank_name: "", file_name:"", client_name:""}

const PdfBankStatementFormPage = () => {
  const navigate = useNavigate();
  const [pdfFormData, setPdfFormData] = useState(initiatialPdfFormDataState)    
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");



  const onFormFieldChange = (event: any) => {
    setPdfFormData({
        ...pdfFormData,
        [event.target.name]: event.target.value
    })    
  }    


  const onFormFileChange = (event: any) => {
      setPdfFormData({
          ...pdfFormData,
          file: event.target.files[0],
          file_name: event.target.value,
      })    
  }
  const onSelectChange = (event: any) => {
      console.log(event.target)
      setPdfFormData({
          ...pdfFormData,
          bank_name: event.target.value,
      })    

  }
  const onDateFieldChange = (date: any) => {
      setPdfFormData({
          ...pdfFormData,
          statement_date: date
      })    
  }
  const onSubmitHandler = (event: any) => {
    event.preventDefault();
    const {file, statement_date, bank_name, client_name} = pdfFormData;
    setIsLoading(true);
    setPdfFormData(initiatialPdfFormDataState);

    let formatted_statement_date = moment(statement_date).format("DD-MM-yyyy");

    console.log("Statement Date")
    console.log(formatted_statement_date)
    
    getPresignedUrl(bank_name, formatted_statement_date)
    .then(response => {
      console.log(response.data);
      let responseData = response.data
      let responseFileName = responseData.file_name
      let responsePresignedUrl = responseData.url
      if (responseFileName && responsePresignedUrl) {
        putPdfStatementWithPresignedUrl(responsePresignedUrl, file)
        .then(response => {
          console.log(response);
          if (response.status == 200){
            triggerPdfBankStatementAnalysis(responseFileName, formatted_statement_date, bank_name, client_name)
            .then(response => {
              console.log(response);
              navigate("/dashboard");
            })
            .catch(err => {
                setErrorMessage(err.response.data.message);
                window.scrollTo(0, 0)
              setTimeout(()=> {
                  setErrorMessage("");
              }, 5000);
              setIsLoading(false);

            });
          } else {
            throw "Failed at uploading file via the presigned url"
          }
        })
        .catch(err => {
          setErrorMessage(err.response.data.message);
          window.scrollTo(0, 0)
          setTimeout(()=> {
              setErrorMessage("");
          }, 5000);

          setIsLoading(false);
        })

      } else {
        throw "presigned Url is not returned"
      }
    })
    .catch(err => {
      setErrorMessage(err.response.data.message);
      window.scrollTo(0, 0)
      setTimeout(()=> {
          setErrorMessage("");
      }, 5000);
      setIsLoading(false);
    });

  }

  return(
    <DashboardTemplate isLoading={isLoading} errorMessage={errorMessage}>
      <div className="pdf-converter-page">
        <div className="pdf-converter-form-container">
          <h3 className="form-title">Upload your bank statements in PDF format</h3>
          <form onSubmit={onSubmitHandler} >
            <TextField fieldType="text" label="Client Name" fieldName="client_name" onChangeHandler={onFormFieldChange}/>
            <BankSelectField selectChangeHandler={onSelectChange} />
            <DateField selected={pdfFormData.statement_date} label="Statement Date" dateChangeHandler={onDateFieldChange} />
            <FileField label="File" fieldName="file" value={pdfFormData.file_name} onChangeHandler={onFormFileChange}/>
            <SubmitBtn buttonType="submit" buttonName="Convert Pdf" />
          </form>
        </div>
      </div>
    </DashboardTemplate>
  )

}

export default PdfBankStatementFormPage;