import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./date_field.styles.scss";

const DateField = ({selected, label, dateChangeHandler}: {selected: Date, label: string, dateChangeHandler: any}) => {

  return(
    <div className="date-field-container">
      <label className="field-label">{label}</label>
      <DatePicker selected={selected} onChange={dateChangeHandler} dateFormat="dd-MM-yyyy" className="date-field" />
    </div>
  )
}

export default DateField;