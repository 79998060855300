import axios from "axios";
const API_URL = "https://c2vafv5uzj.execute-api.ap-southeast-1.amazonaws.com"
const FRONT_END_URL = "https://www.maveneight.cloud"

export const cognitoSignup = (username: string, email: string, password: string, confirmPassword: string) => {
  return axios.post(API_URL + "/dev/user/signup", {
    username,
    email,
    password,
    confirmPassword
    },
    { headers: {
      'Access-Control-Allow-Origin': FRONT_END_URL,
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token"
      }
  });
};

export const cognitoConfirmSignup= (username: string, password: string, code: string) => {
  return axios.post(API_URL + "/dev/user/confirm_signup", {
    username,
    password,
    code,
    },
    { headers: {
      'Access-Control-Allow-Origin':  FRONT_END_URL,
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token"
      }
  });
};

export const cognitoSignin = (username: string, password: string) => {
  return axios
    .post(API_URL + "/dev/user/singin", {
      username,
      password,
    },
    { headers: {
      'Access-Control-Allow-Origin': FRONT_END_URL,
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token"
      }
    });
};

export const cognitoSignout = () => {
  localStorage.removeItem("user");
};


export const cognitoGetPasswordResetCode= (username: string) => {
  return axios.post(API_URL + "/dev/user/forgot_password", {
    username,
    },
    { headers: {
      'Access-Control-Allow-Origin':  FRONT_END_URL,
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token"
      }
  });
};

export const cognitoConfirmResetPassword= (username: string, password: string, code: string) => {
  return axios.post(API_URL + "/dev/user/confirm_forgot_password", {
    username,
    password,
    code,
    },
    { headers: {
      'Access-Control-Allow-Origin':  FRONT_END_URL,
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token"
      }
  });
};
